import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthInitialized, setIsAuthInitialized] = useState(false);
    const [hasReauthenticated, setHasReauthenticated] = useState(false);
    const [nextPage, setNextPage] = useState(''); // Add state for the next page
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setIsAuthInitialized(true);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const markUserAsReauthenticated = () => {
        setHasReauthenticated(true);
    };

    const resetReauthenticationStatus = () => {
        setHasReauthenticated(false);
    };

    // Function to set the next page for navigation after reauthentication
    const setNextPageAfterReauth = (page) => {
        setNextPage(page);
    };

    // Function to get the next page for navigation after reauthentication
    const getNextPageAfterReauth = () => {
        return nextPage;
    };

    // Conditional rendering based on the loading state
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{
            currentUser,
            isAuthInitialized,
            hasReauthenticated,
            markUserAsReauthenticated,
            resetReauthenticationStatus,
            setNextPageAfterReauth, // Make sure to include these in your context provider value
            getNextPageAfterReauth,
        }}>
            {children}
        </AuthContext.Provider>
    );
};
