import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import DashboardLayout from './DashboardLayout';
import Overview from './Overview';
import Balance from './Balance';
import Earnings from './Earnings';
import Costs from './Costs';
import Calculator from './Calculator';
import Profile from './Profile';
import ChangeEmailPage from './ChangeEmailPage';
import ReauthenticatePage from './ReauthenticatePage';
import NewPasswordPage from './NewPasswordPage';
import ProtectedRoute from './ProtectedRoute';
import DeleteAccountConfirmPage from './DeleteAccountConfirmPage';
import { AuthProvider, useAuth } from './AuthContext'; // Ensure this import includes useAuth
import './styles.css';

function RootRedirect() {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/dashboard" /> : <Navigate to="/signup" />;
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<RootRedirect />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<ProtectedRoute requireAuth={false}><Overview /></ProtectedRoute>} />
              <Route path="overview" element={<ProtectedRoute requireAuth={false}><Overview /></ProtectedRoute>} />
              <Route path="balance" element={<ProtectedRoute requireAuth={false}><Balance /></ProtectedRoute>} />
              <Route path="earnings" element={<ProtectedRoute requireAuth={false}><Earnings /></ProtectedRoute>} />
              <Route path="costs" element={<ProtectedRoute requireAuth={false}><Costs /></ProtectedRoute>} />
              <Route path="calculator" element={<ProtectedRoute requireAuth={false}><Calculator /></ProtectedRoute>} />
              <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="reauthenticate" element={<ProtectedRoute><ReauthenticatePage /></ProtectedRoute>} />
              <Route path="new-password" element={<ProtectedRoute><NewPasswordPage /></ProtectedRoute>} />
              <Route path="/dashboard/change-email" element={<ProtectedRoute><ChangeEmailPage /></ProtectedRoute>} />
              <Route path="/dashboard/delete-account-confirm" element={<ProtectedRoute><DeleteAccountConfirmPage /></ProtectedRoute>} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
