import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import { auth } from './firebase';
import { useAuth } from './AuthContext';
import './Profile.css';

function Profile() {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { resetReauthenticationStatus, setNextPageAfterReauth } = useAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Check if the user signed in with email/password
        const isEmailSignIn = user.providerData.some(p => p.providerId === 'password');
        setCurrentUser({
          email: user.email,
          emailVerified: user.emailVerified,
          isEmailSignIn // new state property
        });
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      resetReauthenticationStatus();
      navigate('/login');
    }).catch((error) => {
      console.error("Sign out error:", error);
    });
  };

  const requestEmailVerification = () => {
    if (currentUser && !currentUser.emailVerified) {
      sendEmailVerification(auth.currentUser).then(() => {
        alert('Verification email sent.');
      }).catch((error) => {
        console.error("Error sending verification email:", error);
      });
    }
  };

  const handleChangePasswordClick = () => {
    if (currentUser.isEmailSignIn) {
      setNextPageAfterReauth('/dashboard/new-password');
      navigate('/dashboard/reauthenticate');
    }
  };

  const handleChangeEmailClick = () => {
    if (currentUser.isEmailSignIn) {
      setNextPageAfterReauth('/dashboard/change-email');
      navigate('/dashboard/reauthenticate');
    }
  };


    // Placeholder function for resetting account data
    const handleResetAccountData = () => {
      alert('Reset account data functionality is not implemented yet.');
    };

const handleDeleteAccount = () => {
  // Specify the next page/action after reauthentication
  setNextPageAfterReauth('/dashboard/delete-account-confirm');
  navigate('/dashboard/reauthenticate');
};

  // Render buttons only if user signed in via email/password
  const renderEmailAndPasswordOptions = () => {
    if (currentUser && currentUser.isEmailSignIn) {
      return (
        <>
          <div className="section-container">
            <div className="section-title">Password</div>
            <div className="form-group">
              <button className="btn" onClick={handleChangePasswordClick}>Change Password</button>
            </div>
          </div>

          <div className="section-container">
            <div className="section-title">Email</div>
            <p>Email verification status: {currentUser.emailVerified ? "Verified" : "Not Verified"}</p>
            {!currentUser.emailVerified &&
              <div className="form-group">
                <button className="btn" onClick={requestEmailVerification}>Resend verification email</button>
              </div>
            }
            <div className="form-group">
              <button className="btn" onClick={handleChangeEmailClick}>Change Email</button>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className={`profile-container ${theme}-theme`}>
      <h1>User Profile</h1>
      {currentUser ? (
        <div className="user-info">
          <div className="section-container">
            <div className="section-title">User</div>
            <p>Username: {currentUser.email}</p>
            <div className="form-group">
              <button className="btn" onClick={handleSignOut}>Sign Out</button>
            </div>
          </div>
          
          {renderEmailAndPasswordOptions()}

          <div className="section-container">
            <div className="section-title">Account Data</div>
            <div className="form-group">
              <button className="btn danger" onClick={handleResetAccountData}>Reset Account Data</button>
            </div>
            <div className="form-group">
              <button className="btn danger" onClick={handleDeleteAccount}>Delete Account</button>
            </div>
          </div>
        </div>
      ) : (
        <p>No user is logged in. This is a test site.</p>
      )}
    </div>
  );
}

export default Profile;
