import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardHeader from './DashboardHeader'; // Importing the DashboardHeader component

function DashboardLayout() {
  return (
    <div className="dashboard-layout"> {/* Ensure there's a base class for overall layout styling, if needed */}
      <DashboardHeader />
      <div className="dashboard-content"> {/* This class should match your CSS for the content area */}
        <Outlet /> {/* Child components will be rendered here */}
      </div>
    </div>
  );
}

export default DashboardLayout;
