import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaHome, FaBalanceScale, FaDollarSign, FaCalculator, FaShoppingCart } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext'; // Import useAuth hook
import ThemeToggle from './ThemeToggle';

import './DashboardHeader.css';

function DashboardHeader() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { theme } = useTheme();
  const { currentUser } = useAuth(); // Get current user from context
  const navigate = useNavigate(); // useNavigate hook for navigation
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  // Navigate to login page
  const navigateToLogin = () => {
    navigate('/login');
  };

  // Style for the icons (similar to ThemeToggle)
  const iconStyle = {
    fontSize: '24px',
    color: theme === 'dark' ? '#87CEEB' : '#0056b3',
  };

  // Style for the buttons (similar to ThemeToggle)
  const buttonStyle = {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className={theme === 'light' ? 'light-theme' : 'dark-theme'}>
      <div className="header">
        <FaBars className="menu-icon" onClick={toggleSidebar} />
        <ThemeToggle />
        {/* Conditional Button based on User Authentication */}
        {currentUser ? (
          <button className="button-hover-effect" style={buttonStyle} onClick={() => navigate('/dashboard/profile')}>
            <FontAwesomeIcon icon={faUser} style={iconStyle} />
          </button>
        ) : (
          <button className="button-hover-effect" style={buttonStyle} onClick={navigateToLogin}>
            <FontAwesomeIcon icon={faSignInAlt} style={iconStyle} />
          </button>
        )}
      </div>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
      <ul>
          <li>
            <NavLink to="/dashboard/overview" onClick={toggleSidebar} activeClassName="active">
              <FaHome /><span>Overview</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/balance" onClick={toggleSidebar} activeClassName="active">
              <FaBalanceScale /><span>Balance</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/earnings" onClick={toggleSidebar} activeClassName="active">
              <FaDollarSign /><span>Earnings</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/costs" onClick={toggleSidebar} activeClassName="active">
              <FaShoppingCart /><span>Costs</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/calculator" onClick={toggleSidebar} activeClassName="active">
              <FaCalculator /><span>Calculator</span>
            </NavLink>
          </li>
          {/* ... other links */}
        </ul>
      </div>
    </div>
  );
}

export default DashboardHeader;
