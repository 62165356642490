import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useTheme } from './ThemeContext';
import ThemeToggle from './ThemeToggle'; // Adjust the path as needed

const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'The email address is not valid. Please enter a valid email address.';
      case 'auth/user-disabled':
        return 'This account has been disabled. Please contact support.';
      case 'auth/user-not-found':
        return 'No user found with this email. Please sign up.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/invalid-credential':
        return 'Invalid credentials. Please try again.';
      // Add more cases as needed for different error codes
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };
  

  function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [showEmailLogin, setShowEmailLogin] = useState(false);
    const navigate = useNavigate();

    const { theme } = useTheme();
    useEffect(() => {
      document.body.className = theme === 'light' ? 'light-theme' : 'dark-theme';
    }, [theme]);
    

    const headerStyles = {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px 20px', // Adjust padding as needed
    };
  
    const handleGoogleLogin = () => {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          navigate('/dashboard'); // Navigate to the dashboard
        })
        .catch((error) => {
          setError(error.message);
        });
    };
  
    const handleLogin = async (event) => {
      event.preventDefault();
      setError(null);
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/dashboard'); // Redirect to the dashboard
      } catch (error) {
        setError(getErrorMessage(error.code));
      }
    };
  
    const styles = {
      whiteButton: {
        backgroundColor: 'white', // White background
        color: '#333', // Dark text for contrast
        padding: '15px 20px',
        margin: '10px 0',
        cursor: 'pointer',
        width: '100%',
        border: '1px solid #ccc', // Border for definition
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Subtle shadow
      },
      emailButton: {
        backgroundColor: '#4285F4', // Google blue for the Email sign-up button
        color: 'white',
        // Other styles remain the same as in styles.button
        padding: '15px 20px',
        margin: '10px 0',
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Subtle shadow
      },
      container: {
        maxWidth: '400px',
        margin: '0 auto',
        textAlign: 'center',
        padding: '20px', // Choose the padding you prefer
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        backgroundColor: theme === 'light' ? 'white' : 'black',
        color: theme === 'light' ? 'black' : 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        width: '100%',
      },
      input: {
        width: '100%',
        padding: '15px 20px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
        margin: '10px 0', // Choose the margin you prefer
      },      
      form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        width: '100%' // Ensure the form stretches to the full width of the container
      },
      inputContainer: {
        width: '100%', // Same as buttons
        margin: '0px 0', // Reduced margin to bring inputs closer
        padding: '0', // No padding
        boxSizing: 'border-box', // Include padding and border in the width calculation
      },
        button: {
        backgroundColor: '#4285F4', // Google blue for the Google sign-in button
        color: 'white',
        padding: '15px 20px',
        margin: '10px 0',
        cursor: 'pointer',
        width: '100%',
        border: 'none',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // Add a subtle shadow
        boxSizing: 'border-box',
      },
      linkContainer: {
        marginTop: '20px'
      },
      link: {
        color: '#0077cc',
        textDecoration: 'none',
        fontSize: '0.9em' // Slightly smaller font for non-primary actions
      },
      error: {
        color: 'red'
      },
      legalText: {
        fontSize: '0.8em', // Small font for legal text
        marginTop: '15px',
        color: '#666' // Less prominence with a grey color
      },
      icon: {
        marginRight: '10px', // Add space between the icon and the text
      },
      cancelButton: {
        backgroundColor: 'white',
        color: '#333',
        padding: '15px 20px',
        margin: '10px 0',
        cursor: 'pointer',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
      },
    
    };
    
    return (
      <div className={theme === 'light' ? 'light-theme' : 'dark-theme'}>
            <div style={headerStyles}>
        <ThemeToggle />
      </div>

      <div style={styles.container}>
        <h1>Welcome Back! Choose your login method.</h1>
        {error && <p style={styles.error}>{error}</p>}
        {!showEmailLogin && (
          <>
            <button onClick={handleGoogleLogin} style={styles.whiteButton}>
              <FontAwesomeIcon icon={faGoogle} style={styles.icon} /> Sign in with Google
            </button>
            <button onClick={() => setShowEmailLogin(true)} style={styles.emailButton}>
              <FontAwesomeIcon icon={faEnvelope} style={styles.icon} /> Sign in with Email
            </button>
          </>
        )}
        {showEmailLogin && (
          <form onSubmit={handleLogin} style={styles.form}>
            <div style={styles.inputContainer}>
              <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required style={styles.input} />
            </div>
            <div style={styles.inputContainer}>
              <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required style={styles.input} />
            </div>
            <button type="submit" style={styles.emailButton}>Login</button>
            <button type="button" onClick={() => setShowEmailLogin(false)} style={styles.cancelButton}>Cancel</button>
          </form>
        )}
        <div style={styles.linkContainer}>
          <span>New to pdfzujpg? </span>
          <Link to="/signup" style={styles.link}>Sign up</Link>
        </div>
        <p style={styles.legalText}>By continuing, you acknowledge that you accept pdfzujpg's Terms of Use and Privacy Policy.</p>
      </div>
      </div>
    );
  }
  
  export default LoginPage;
  