import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCfI5xpd36LzyV0sEwhx8_-0g2HYrj1KwY",
    authDomain: "pdfzujpg-test.firebaseapp.com",
    projectId: "pdfzujpg-test",
    storageBucket: "pdfzujpg-test.appspot.com",
    messagingSenderId: "857262383346",
    appId: "1:857262383346:web:7a859f6f7a759efcfbf06b",
    measurementId: "G-8BGH199LP4"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);
  
  // Set the persistence
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      // Now the auth state will be persisted in the local storage and will survive browser refreshes
    })
    .catch((error) => {
      console.error("Error setting persistence:", error);
    });
  
  export { auth };