import React from 'react';
import { useTheme } from './ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  const iconStyle = {
    fontSize: '24px', // Adjust the size as needed
    color: theme === 'dark' ? '#87CEEB' : '#0056b3', // Use the same blue shades as in the sidebar
  };

  return (
    <button onClick={toggleTheme} className="button-hover-effect" style={{
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      display: 'flex', // Ensures the icon centers in the button if there's no text
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} style={iconStyle} />
    </button>
  );
};

export default ThemeToggle;
