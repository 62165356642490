import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useTheme } from './ThemeContext';
import { auth } from './firebase';

function Costs() {
  const [currentUser, setCurrentUser] = useState(null);
  const { theme } = useTheme();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className={theme === 'light' ? 'light-theme' : 'dark-theme'}>
      <div className="content">
        <h1>Costs</h1>
        {currentUser ? (
          <p>Hello {currentUser.email}, your balance is 0.</p>
        ) : (
          <p>No user is logged in.</p>
        )}
      </div>
    </div>
  );
}

export default Costs;
