import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyBeforeUpdateEmail, signOut } from 'firebase/auth';
import { auth } from './firebase';
import { useAuth } from './AuthContext';
import './Profile.css';

function ChangeEmailPage() {
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { hasReauthenticated } = useAuth();

  useEffect(() => {
    if (!hasReauthenticated) {
      navigate('/dashboard/reauthenticate');
    }
  }, [hasReauthenticated, navigate]);

  const handleEmailChange = () => {
    if (!newEmail) {
      setError('Please enter an email address.');
      return;
    }
  
    verifyBeforeUpdateEmail(auth.currentUser, newEmail).then(() => {
      alert('A verification email has been sent to your new email address. Please verify it to complete the update.');
      signOut(auth); // Optionally sign out the user
      navigate('/login'); // Navigate the user to the login page
    }).catch((error) => {
      setError(`Failed to initiate email update. ${error.message}`);
    });
  };

  return (
    <div className="profile-container">
      <div className="section-container">
        <h1>Change Email</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className="form-group">
          <input
            type="email"
            placeholder="Enter New Email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <button className="btn" onClick={handleEmailChange}>Initiate Email Change</button>
        </div>
      </div>
    </div>
  );
}

export default ChangeEmailPage;
