// ReauthenticatePage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { reauthenticateWithCredential, EmailAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from './firebase';
import { useAuth } from './AuthContext';
import './Profile.css';

function ReauthenticatePage() {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { markUserAsReauthenticated, getNextPageAfterReauth } = useAuth();

    useEffect(() => {
        // Ensure there's a fallback if no next page is defined
        if (!getNextPageAfterReauth()) {
            navigate('/dashboard/profile'); // Fallback to profile if no specific redirection is set
        }
    }, [navigate, getNextPageAfterReauth]);

    const reauthenticate = (password) => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, password);

        reauthenticateWithCredential(user, credential)
            .then(() => {
                // After successful reauthentication, sign in the user again to ensure the session is considered recent
                return signInWithEmailAndPassword(auth, user.email, password);
            })
            .then(() => {
                // Mark user as reauthenticated and navigate to the intended page
                markUserAsReauthenticated();
                navigate(getNextPageAfterReauth() || '/dashboard/profile');
            })
            .catch((error) => {
                setError("Failed to reauthenticate. " + error.message);
            });
    };

    return (
        <div className="profile-container">
            <div className="section-container">
                <h1>Reauthenticate</h1>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Enter current password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <button className="btn" onClick={() => reauthenticate(password)}>Confirm</button>
                </div>
            </div>
        </div>
    );
}

export default ReauthenticatePage;
