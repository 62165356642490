import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './firebase';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useTheme } from './ThemeContext';
import ThemeToggle from './ThemeToggle';
import { sendEmailVerification } from "firebase/auth";


function SignupPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showEmailSignup, setShowEmailSignup] = useState(false);
  const navigate = useNavigate();

  // Use the theme from the context
  const { theme } = useTheme();
  useEffect(() => {
    document.body.className = theme === 'light' ? 'light-theme' : 'dark-theme';
  }, [theme]);
  

  const headerStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px', // Adjust padding as needed
  };

  const handleGoogleSignup = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/dashboard'); // Navigate to the dashboard
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleSkip = () => {
    navigate('/dashboard'); // Navigate to the dashboard
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
  
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Firebase automatically sends a verification email if configured in the console
      // Optionally, you can manually send a verification email like this:
      await sendEmailVerification(userCredential.user);
      navigate('/dashboard'); // Navigate to the dashboard after successful signup
    } catch (error) {
      setError(getErrorMessage(error.code));
    }
  };
  
  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please use a different email.';
      case 'auth/invalid-email':
        return 'The email address is invalid. Please enter a valid email address.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };

  const styles = {
    whiteButton: {
      backgroundColor: 'white', // White background
      color: '#333', // Dark text for contrast
      padding: '15px 20px',
      margin: '10px 0',
      cursor: 'pointer',
      width: '100%',
      border: '1px solid #ccc', // Border for definition
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Subtle shadow
    },
    emailButton: {
      backgroundColor: '#4285F4', // Google blue for the Email sign-up button
      color: 'white',
      // Other styles remain the same as in styles.button
      padding: '15px 20px',
      margin: '10px 0',
      cursor: 'pointer',
      width: '100%',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Subtle shadow
    },
  
    container: {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      padding: '20px',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' // Use a modern font
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '20px',
      width: '100%' // Ensure the form stretches to the full width of the container
    },
    inputContainer: {
      width: '100%', // Same as buttons
      margin: '0px 0', // Reduced margin to bring inputs closer
      padding: '0', // No padding
      boxSizing: 'border-box', // Include padding and border in the width calculation
    },
      button: {
      backgroundColor: '#4285F4', // Google blue for the Google sign-in button
      color: 'white',
      padding: '15px 20px',
      margin: '10px 0',
      cursor: 'pointer',
      width: '100%',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // Add a subtle shadow
      boxSizing: 'border-box',
    },
    linkContainer: {
      marginTop: '20px'
    },
    link: {
      color: '#0077cc',
      textDecoration: 'none',
      fontSize: '0.9em' // Slightly smaller font for non-primary actions
    },
    error: {
      color: 'red'
    },
    legalText: {
      fontSize: '0.8em', // Small font for legal text
      marginTop: '15px',
      color: '#666' // Less prominence with a grey color
    },
    icon: {
      marginRight: '10px', // Add space between the icon and the text
    },
    input: {
      width: '100%',
      padding: '15px 20px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
      margin: '10px 0', // Merged property
    },    
    cancelButton: {
      backgroundColor: 'white',
      color: '#333',
      padding: '15px 20px',
      margin: '10px 0',
      cursor: 'pointer',
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
    },
  
  };

  return (
    <div className={theme === 'light' ? 'light-theme' : 'dark-theme'}>
    <div style={headerStyles}>
      <ThemeToggle />
    </div>
    <div style={styles.container}>
      <h1>Welcome! Choose how you'd like to get started.</h1>
      {error && <p style={styles.error}>{error}</p>}
      {!showEmailSignup && (
        <>
        <button onClick={handleSkip} style={styles.whiteButton}>
          <FontAwesomeIcon icon={faArrowRight} style={styles.icon} /> Skip for now
        </button>
        <button onClick={handleGoogleSignup} style={styles.whiteButton}>
          <FontAwesomeIcon icon={faGoogle} style={styles.icon} /> Sign up with Google
        </button>
        <button onClick={() => setShowEmailSignup(true)} style={styles.emailButton}>
          <FontAwesomeIcon icon={faEnvelope} style={styles.icon} /> Sign up with Email
        </button>
        </>
      )}
      {showEmailSignup && (
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputContainer}>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required style={styles.input} />
          </div>
          <div style={styles.inputContainer}>
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required style={styles.input} />
          </div>
          <button type="submit" style={styles.emailButton}>Sign Up</button>
          <button type="button" onClick={() => setShowEmailSignup(false)} style={styles.cancelButton}>Cancel</button>
        </form>
      )}
      <div style={styles.linkContainer}>
        <span>Already have an account? </span>
        <Link to="/login" style={styles.link}>Sign in</Link>
      </div>
      <p style={styles.legalText}>By continuing, you acknowledge that you accept pdfzujpg's Terms of Use and Privacy Policy.</p>
    </div>
    </div>
  );
  
}

export default SignupPage;
