import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import { auth } from './firebase';

function Overview() {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser({
          email: user.email,
          emailVerified: user.emailVerified
        });
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error("Sign out error:", error);
    });
  };

  return (
    <div className={theme === 'light' ? 'light-theme' : 'dark-theme'}>
      <div className="content">
        <h1>Dashboard</h1>
        {currentUser ? (
          <>
            <p>Welcome {currentUser.email}!</p>
            {currentUser.emailVerified ? (
              <p>Your email is verified.</p>
            ) : (
              <p>Your email is not verified. Please check your inbox for a verification email.</p>
            )}
            <button onClick={handleSignOut}>Sign Out</button>
          </>
        ) : (
          <p>No user is logged in. This is a test site.</p>
        )}
      </div>
    </div>
  );
}

export default Overview;
