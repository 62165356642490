import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

// Add an optional requireAuth prop with a default value of true
const ProtectedRoute = ({ children, requireAuth = true }) => {
  const { currentUser } = useAuth();

  // If requireAuth is true and no currentUser is found, redirect to the login page
  if (requireAuth && !currentUser) {
    return <Navigate to="/login" />;
  }

  // If requireAuth is false or currentUser exists, render the children components
  return children;
};

export default ProtectedRoute;
