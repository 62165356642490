import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from "firebase/auth";
import { auth } from './firebase'; // Ensure this path is correctly pointed
import { useAuth } from './AuthContext'; // Import useAuth
import './Profile.css'; // Reuse the CSS for consistent styling

function NewPasswordPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { hasReauthenticated } = useAuth(); // Destructure the new state

  // Redirect if the user hasn't reauthenticated
  useEffect(() => {
    if (!hasReauthenticated) {
      navigate('/dashboard/reauthenticate');
    }
  }, [hasReauthenticated, navigate]);

  const changePassword = () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    updatePassword(auth.currentUser, newPassword).then(() => {
      alert('Password updated successfully.');
      navigate('/dashboard/profile'); // Redirect to profile or desired page after success
    }).catch((error) => {
      setError("Failed to update password. " + error.message);
    });
  };

  return (
    <div className="profile-container">
      <div className="section-container">
        <h1>Change Password</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className="form-group">
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <button className="btn" onClick={changePassword}>Change Password</button>
        </div>
      </div>
    </div>
  );
}

export default NewPasswordPage;
