import React from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from "firebase/auth";
import { auth } from './firebase'; // Ensure this is the correct path to your firebase.js configuration file
import { useAuth } from './AuthContext';
import './Profile.css';

function DeleteAccountConfirmPage() {
  const navigate = useNavigate();
  const { setNextPageAfterReauth } = useAuth();

  const confirmDelete = async () => {
    try {
      await deleteUser(auth.currentUser);
      alert('Account deleted successfully.');
      navigate('/login');
    } catch (error) {
      console.error("Error deleting account:", error);
      if (error.code === 'auth/requires-recent-login') {
        // If the error is due to a non-recent login, direct the user to reauthenticate
        setNextPageAfterReauth('/dashboard/delete-account-confirm');
        navigate('/dashboard/reauthenticate');
      }
    }
  };

  return (
    <div className="profile-container">
      <h1>Confirm Account Deletion</h1>
      <p>Are you sure you want to delete your account? This action cannot be undone.</p>
      <button onClick={confirmDelete}>Confirm Delete</button>
    </div>
  );
}

export default DeleteAccountConfirmPage;
